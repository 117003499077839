import React from "react";
import "./foundation.scss";
import DataInfo from "./DataInfo";
import Methodology from "./Methodology";
import KeyFeatures from "./KeyFeatures";
import NextStep from "./NextStep";
function foundation() {
  return (
    <>
      <section className="hero-section">
        <div className="hero-content">
          <h2>Data Foundation™</h2>
          <span>
            An automated ETL tool that extracts data from any data source,
            transforms it, and makes it usable for FortifAI to generate
            insights.
          </span>
          <a href="/meeting">
            {" "}
            <button className="demo-button">Schedule a Demo</button>
          </a>
        </div>
      </section>
      <DataInfo />
      <Methodology />
      <KeyFeatures />
      <NextStep />
    </>
  );
}

export default foundation;
