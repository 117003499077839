import Blog from "../../Blog/Blog";

const ExecutiveMalfeasance = () => {
  const blogData = {
    title: "Executive Malfeasance and Bottom-Tier Workers'",
    subtitle: "Insights",
    topText: [
      "In the last few years, we have seen too many companies that have been hit by corporate fraud. Many of them have declared bankruptcy, shut down or significantly scaled down their operations.As is routine after such public implosions, a spotlight shines on top management and the scandal becomes a case study of executive malfeasance.",
    ],
    listText: {
      title: "",
      subText: [
        "But for Jung Ho Choi, assistant professor of accounting at Stanford GSB, a different narrative remained unvoiced: What about the thousands of employees who lose their jobs overnight? He wondered the same thing about workers at Enron and Waste Management, Xerox and Tyco.",
        "“Midlevel workers and below make up the majority of any firm, and their lives are completely upended just because they happen to work for a fraudulent company,” Choi says. “Workers usually suffer more than management, but they’re hard to trace, and this made me want to understand what happens to them.”",
        "Using the U.S. Census Bureau’s confidential Longitudinal Employer-Household Dynamics dataset, which gathers information on where individuals are employed along with their wages and basic demographics, Choi tracked 362,000 people employed across about 150 firms involved in the scandal between 1989 and 2008. In parallel to this group, he tracked the trajectory of a control group of employees who worked at corresponding companies that did not experience scandal.",
      ],

      listText: [
        {
          boldText: "A 50% Plunge in Earnings",
          text: "With his colleague Brandon Gipper, an associate professor of accounting at Stanford GSB, Choi found that employees who’d worked at firms involved in fraud lost an average of 50% of their cumulative earnings over the subsequent decade compared with their peers at similar firms. ",
        },
        {
          boldText: "",
          text: "To contextualize the scale of this gap, the researchers point out that estimated wage losses in the U.S. caused by the Clean Air Act average about 20%, while losses caused by competition with China are anywhere from 20% to 40%.",
        },
        {
          boldText: "",
          text: "By parsing the data more carefully, the researchers found a stark story of inequity within the troubled firms themselves. When employees of fraudulent companies were divided into two groups — the top 10% and the bottom 90% of earners — workers with lower salaries shouldered the brunt of harms.",
        },
        {
          boldText: "",
          text: "“Workers of a fraudulent firm are likely to leave their county, and even industry, when the fraud is discovered, and those who earn more — the skilled and high-paid workers — are much more mobile,” Choi says. “So lower-paid workers seem to bear the major costs of fraud and suffer more.”",
        },
      ],
    },
    listText2: {
      title: "Secrecy’s Compounding Effect",
      subText: [
        "This inequity is compounded by a novel pattern in which fraudulent firms, during the period of fraud, often try to maintain a front of profitability by expanding their workforce. They do this by quietly shedding more experienced and highly-paid employees and replacing them with a greater number of inexperienced and low-paid workers. Also, this process typically takes place concealed from view — which is one thing that often differentiates fraud from bankruptcy.",
      ],
      listText: [
        {
          boldText: "",
          text: "“If you imagine a case like JCPenney, there is a general understanding that the company is ailing, and so workers or stakeholders can, to some extent, make an employment decision based on the right information,” Choi says. “Fraud cases are completely different. Most employees have no idea of the risks until the very day they lose their jobs and are forced into a difficult labour market.”",
        },
        {
          boldText: "",
          text: "The paper, says Choi, has two key implications for policymakers. On the enforcement side, he suggests that the Securities and Exchange Commission, which tends to have its offices in major cities, should pay more attention to companies that are in distant labour markets.",
        },
        {
          boldText: "There is a two-step rationale for this.",
          text: "First, prior research has demonstrated that fraud detection rates are higher in areas that are physically close to SEC offices.",
        },
        {
          boldText: "",
          text: [
            "Second, when fraudulent companies that are far from SEC offices, in rural markets, collapse, they leave low-wage workers stranded in an especially hostile labour market, as there are rarely companies in the same sector ready to absorb the layoffs. (WorldCom, for example, was headquartered in Clinton, Mississippi — not a telecom hotbed.) Workers who live in major cities are more likely to land on their feet.",
            "Reprinted from Stanford Business School, Dylan Walsh",
          ],
        },
      ],
    },
    listText3: {
      title:
        "A study conducted over 9 years established that organisations with better corporate governance outperform poorly governed organisations. ",
      subText: [""],
      listText: [
        {
          boldText: "",
          text: "Effective Corporate Governance is the secret sauce in an organization’s journey that holds it together. When the principles of governance are at play, it often does not matter to external stakeholders but when it fails, the results are catastrophic as we have seen time and again in some of the most spectacular falls from the grace. It can be argued that cases like Enron and Worldcom in the US and Satyam, DHFL, Geetanjali and Nirav Modi in India are exceptions and representative of extreme cases of promoter/manager greed and willful misconduct. While this may be true,  the fact remains that poor governance impedes value creation.",
        },
        {
          boldText: "",
          text: "While it is certain that willful neglect will be disastrous for any company, how does the quality of a company’s governance impact performance? This article explains how governance plays a significant role in determining the valuation of a company. This topic is supported by a vast amount of research, some of which is presented here.",
        },

        {
          boldText: "",
          text: "At its very core, the objective of effective governance is to protect the interests of shareholders and providers of capital. In recent times, the objectives have evolved to also cover the interests and rights of external stakeholders who deal with the organization on a good faith basis. Accordingly, the definition of governance has also evolved to ensure that businesses are directed and controlled in a manner to ensures responsible decision-making that aims to protect the interests of external stakeholders and provides valuable resources to the organisations.",
        },

        {
          boldText: "",
          text: "Effective and well-functioning governance then is a powerful signalling strategy which assures the shareholders of effective monitoring and control which in turn will result in the shareholders and stakeholders providing the firm with access to valuable resources which it needs for its growth resulting in superior performance.",
        },
      ],

      lastParaText: [""],
    },
    listText4: {
      title:
        "High-governance company stocks outperform low-governance stocks by 9% per year over 9 years ",
      subText: [""],
      listText: [
        {
          boldText: "",
          text: "  Gomers and Ishii (Harvard University) and Metrick (University of Pennsylvania) presented a paper in the Quarterly Journal of Economics in February 2003. This paper presented their research conducted over 9 years from 1990 to 1999 on 1500 large firms. The paper ranked these firms on a governance index (G) after analyzing these firms on 24 governance-related parameters. The G score represented shareholder rights and the firms that ranked high on the G score (strong shareholder rights) consistently outperformed the low G score firms (weak shareholder rights) on overall corporate performance.",
        },
        {
          boldText: "",
          text: "The paper ranked the firms on the G score and the firms that were high on the governance index and provided the highest rights to the shareholders were called the Democracy portfolio and the ones that provided the weakest shareholder rights (low G score) were called the Dictatorship Portfolio.",
        },
        {
          boldText: "",
          text: "The research found that the democracy portfolio outperformed the dictatorship portfolio by 8.5% per year. A $1 investment in a dictatorship portfolio would have grown to $3.39 over 9 years which was a 14% annualized return. In contrast, a $1 investment in the democracy portfolio increased to $7.07 over the same period yielding a 23% annualized return, a difference of 9%.",
        },
        {
          boldText: "",
          text: "The democracy portfolio also had a better firm valuation than the dictator portfolio. This finding was also consistent with a vast study conducted elsewhere that has found a positive correlation between governance and firm value. Further, the paper also presented evidence that in general more democratic firms have better operating performance.",
        },
        {
          boldText: "",
          text: "In India, scrutiny of publicly listed banking stocks will reveal that those banks that have robust governance in place have consistently outperformed peers by a huge margin. Such democracy stocks outperform their dictatorship peers in ROCE, ROE, EBITDA, and CMV/BV and have delivered the highest CAGR over the last 5 years.",
        },
        {
          boldText: "",
          text: "While it is well-established that governance has a significant positive impact on the firm valuation, it is difficult to determine exact linkages. Further, governance requirements differ from industry to industry, some industries need more governance than others.  Also, the need for governance may vary depending on the age of the firms. Younger firms need less governance than mature firms as the boards of younger firms are more focused on the value creation function. On the contrary, in mature firms, where there is a separation of ownership and management, the board is more focused on the value protection function and hence lays significant emphasis on governance as a value protection lever. Irrespective, governance is an area that should be managed proactively and efficiently to maximize firm value.",
        },
      ],
    },

    paraText: {
      title: "What can be done",
      subText: [
        "Irrespective of the industry or the age of the firm, promoters and management should focus on implementing a strong governance framework that works. Some key provisions that are necessary and required by regulators are:",
        "1. Board Independence",
        "2. Audit Committee",
        "3. Board structure & composition",
        "4. Financial & Internal Controls",
        "5. Code of Conduct",
        "6. Policies & Procedures",
        "Determining the adequacy of the governance provisions can be a daunting task as these provisions should be customized to the business and industry needs. Further, such measures should be aligned with the business needs.",
        "Unnecessary bravado can be counter-productive as it can stifle decision-making and hamper business agility.",
      ],
    },

    paraText2: {
      title: "Conclusion",
      subText: [
        "Determining the adequacy of the governance provisions can be a daunting task as these provisions should be customized to the business and industry needs. Further, such measures should be aligned with the business needs. Unnecessary bravado can be counter-productive as it can stifle decision-making and hamper business agility.",
      ],
    },
  };

  return (
    <>
      <Blog blogData={blogData} />
    </>
  );
};

export default ExecutiveMalfeasance;
