import React from "react";
import Data from "../assets/data.svg";
import Hosting from "../assets/hosting.svg";
import Access from "../assets/access.svg";
import Sample from "../assets/security-home.png";
import "./Solutions.scss";
function Security() {
  return (
    <section className="parent-security-container">
      <section className="security-home">
        <div className="center-div">
          <div className="left-container">
            <h2>Security</h2>
            <p>Secure Data &#183; Secure Access &#183; Secure Hosting</p>
            <button>Schedule a Demo</button>
          </div>
          <div className="right-container">
            <img src={Sample} alt="" />
          </div>
        </div>
      </section>

      <section className="security-factors">
        <h2>Security</h2>
        <div className="security-content">
          <div className="unit">
            <img src={Data} alt="" />
            <div className="content">
              <span>Secure Data</span>
              <p>SSL data encryption in motion and at rest</p>
            </div>
          </div>
          <div className="unit">
            <img src={Hosting} alt="" />
            <div className="content">
              <span>Secure Hosting</span>
              <p className="hosting-text">
                Host it on our Azure cloud or your virtual private cloud so your
                data stays with you
              </p>
            </div>
          </div>
          <div className="unit">
            <img src={Access} alt="" />
            <div className="content">
              <span>Secure Access</span>
              <p>Integrated with enterprise SSO for controlled access</p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Security;
