import React from "react";
import "./Insight.scss";
import BlogCard from "./BlogCard";
import BlogFraud from "../assets/blog-fraud.png";
import Blog3 from "../assets/blog3.png";
import Fraud101 from "../assets/fraud101.png";
export default function Insight() {
  const blogs = [
    {
      id: 5,
      imageUrl: BlogFraud,
      date: "May 31, 2024",
      heading: "Fraud Risk Management Workflow Automation Using SynthAI",
      description:
        "To date, generative AI applications have overwhelmingly focused on the divergence of information.That is, they create new content based on a set of instructions.",
      link: "/workflow-automation",
    },

    // {
    //   id: 1,
    //   imageUrl: Blog2,
    //   date: "May 1, 2024",
    //   heading: "Generative AI for Fraud Detection",
    //   description:
    //     "Generative AI for Fraud Detection will bring in a new era in financial safeguarding for higher business outcomes and huge cost savings by way of preventing frauds and reducing cost of compliance.",
    //   link: "/blog/intro-to-reusable-components",
    // },
    {
      id: 2,
      imageUrl: Blog3,
      date: "May 1, 2024",
      heading: "Pre-investment Forensic Due Diligence",
      description:
        "How Forensic Due Diligence can significantly Impact DPI (Distributed to Paid-in Capital) and TVPI (Total Value to Paid-in Capital) metrics of a fund.",
      link: "/blog/pre-investment-forensic-due-diligence",
    },
    {
      id: 3,
      imageUrl: Blog3,
      date: "May 1, 2024",
      heading: "Executive Malfeasance and Bottom-Tier Workers'",
      description:
        "In the last few years, we have seen too many companies that have been hit by corporate fraud. Many of them have declared bankruptcy, shut down or significantly scaled down their operations. ",
      link: "/blog/executive-malfeasance",
    },
    {
      id: 4,
      imageUrl: Fraud101,
      date: "May 1, 2024",
      heading:
        "Fraud 101: What is Fraud - It is both simpler and more complicated than you think",
      description:
        "“Fraud” is any activity that relies on deception in order to achieve a gain. Fraud becomes a crime when it is a “knowing misrepresentation of the truth or concealment of a material fact to induce another to act to his or her detriment” (Black’s Law Dictionary).",
      link: "/blog/fraud-101",
    },
    {
      id: 1,
      imageUrl: BlogFraud,
      date: "April 5, 2024",
      heading: "Generative AI for Fraud Detection",
      description:
        "Generative AI for Fraud Detection will bring in a new era in financial safeguarding for higher business outcomes and huge cost savings by way of preventing frauds and reducing cost of compliance.",
      link: "/blog/gen-ai-fraud-detection",
    },
  ];

  return (
    <section>
      <section className="insight-container">
        <div className="center-div">
          <h2>Insights</h2>
          <p>Resources &gt; Insights</p>
        </div>
      </section>
      <section className="blog-container-insight">
        <h2>Recently Posted Insights</h2>
        <div className="blog-grid">
          {blogs.map((blog) => (
            <BlogCard
              key={blog.id}
              imageUrl={blog.imageUrl}
              date={blog.date}
              heading={blog.heading}
              description={blog.description}
              link={blog.link}
            />
          ))}
        </div>
      </section>
    </section>
  );
}
