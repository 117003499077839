import Blog from "../../Blog/Blog";

const Fraud101 = () => {
  const blogData = {
    title: "Fraud 101: What is Fraud",
    subtitle: "Insights",
    topText: [""],
    listText: {
      title: "What is Fraud Anyway?",
      subText: [""],
      listText: [
        {
          boldText: "",
          text: " “Fraud” is any activity that relies on deception in order to achieve a gain. Fraud becomes a crime when it is a “knowing misrepresentation of the truth or concealment of a material fact to induce another to act to his or her detriment” (Black’s Law Dictionary). In other words, if you lie in order to deprive a person or organization of their money or property, you’re committing fraud. ",
        },
        {
          boldText: "Why Do People Commit Fraud? ",
          text: "  The most widely accepted explanation for why some people commit fraud is known as the Fraud Triangle. The Fraud Triangle was developed by Dr. Donald Cressey, a criminologist whose research on embezzlers produced the term “trust violators.” ",
        },
        {
          boldText: "",
          text: "The Fraud Triangle hypothesizes that if all three components are present — unshareable financial need, perceived opportunity and rationalization — a person is highly likely to pursue fraudulent activities. As Dr. Cressey explains in the Fraud Examiners Manual:",
        },
        {
          boldText: "",
          text: "“When the trust violators were asked to explain why they refrained from violation of other positions of trust they might have held at previous times, or why they had not violated the subject position at an earlier time, those who had an opinion expressed the equivalent of one or more of the following quotations: (a) ‘There was no need for it like there was this time.’ (b) ‘The idea never entered my head.’ (c) ‘I thought it was dishonest then, but this time it did not seem dishonest at first.’",
        },
      ],
    },
    listText2: {
      title: "Categories of Fraud",
      subText: [""],
      listText: [
        {
          boldText: "",
          text: "  Unfortunately, fraud is so common that it can be categorized in countless ways. But fundamentally, every type of fraud is either organizational or individual. Let’s look at some key characteristics of each. ",
        },
        {
          boldText: "Against individuals ",
          text: " This is when a single person is targeted by a fraudster — including identity theft, phishing scams and “advance-fee” schemes. Perhaps one of the most noteworthy and devastating individual frauds is the Ponzi scheme. ",
        },
        {
          boldText: "Internal organizational fraud ",
          text: " Sometimes called “occupational fraud,” this is when an employee, manager or executive of an organization deceives the organization itself. Think embezzlement, cheating on taxes, and lying to investors and shareholders. ",
        },
        {
          boldText: "External organizational fraud ",
          text: " This includes fraud committed against an organization from the outside, such as vendors who lie about the work they did, demand bribes from employees and rig costs. But customers sometimes defraud organizations, such as when they submit bad checks or try to return knock-off or stolen products. And increasingly, technology threatens organizations with theft of intellectual property or customer information.",
        },
      ],
    },

    // listText4: {
    //   title: "The Fraud Tree",
    // subText: [
    //   " ",
    // ],
    //   subText: [
    //     "Occupational fraud contains a wide variety of specific schemes — each of which with its own tactics and goals. That’s why we created the Fraud Tree, which classifies every type of occupational fraud.",
    //   ],
    //   listText: [
    //     {
    //       boldText: "",
    //       text: "",
    //     },
    //     {
    //       boldText: "",
    //       text: "",
    //     },
    //     {
    //       boldText: "",
    //       text: "",
    //     },
    //   ],
    // },
    paraText: {
      title: "",
      subText: [
        "Occupational fraud contains a wide variety of specific schemes — each of which with its own tactics and goals. That’s why we created the Fraud Tree, which classifies every type of occupational fraud. ",
        "Remember, more often than not, where there is smoke, there is fire. ",
      ],
    },
    paraText2: {
      title: "",
      subText: [""],
    },
  };

  return (
    <>
      <Blog blogData={blogData} />
    </>
  );
};

export default Fraud101;
