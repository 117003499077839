import React, { useEffect, useState } from "react";
import "./BookDemo.scss";
import MuiLoader from "../MuiLoader/MuiLoader";

function BookDemo() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;

    script.onload = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000); 
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <MuiLoader />
      ) : (
        <div className="bookdemo-container">
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/contact-iw78/demo"
            style={{ minWidth: "320px", height: "700px" }}
          />
        </div>
      )}
    </>
  );
}

export default BookDemo;
