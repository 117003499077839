import React from "react";
import Methodology from "./methodology.png";
import "./foundation.scss";
export default function Mehthodology() {
  return (
    <section className="methodology">
      <h2>Data Foundation Methodology</h2>
      <img src={Methodology} alt="" />
    </section>
  );
}
