import React from "react";
import "./CookiePolicy.scss";

function CookiePolicy() {
  return (
    <section className="cookie-policy">
      <h1>Website Cookies Policy</h1>
      <div className="date">
        <span>Effective date: April 18,2024</span>
      </div>

      <div className="cookie-general">
        <h3>1.GENERAL</h3>
        <ul>
          <li>
            This Website with the URL of www.fortifai.io ("Website/Site") is
            operated by QTC Technology Private Limited ("We/Our/Us"). We are
            committed to protecting and respecting your privacy. We do use
            cookies and process your personal data in accordance with the IT
            Act, 2000 (21 of 2000) and other national and state laws which
            relate to the processing of personal data. Please read the following
            carefully to understand our views and practices regarding your
            personal data.
          </li>
          <li>
            Our cookies policy explains what cookies are, how we use cookies,
            how third-parties we may partner with may use cookies on the Site,
            your choices regarding cookies and further information about
            cookies.
          </li>
          <li>
            We do use cookies in order to provide and continually improve our
            products and services.
          </li>
          <li>
            The cookies we use do not store personally identifiable information
            nor can they harm your computer.
          </li>
          <li>
            By using this Site, you consent to use the cookies. If you do not
            agree to such use, please refrain from using this Website.
          </li>
        </ul>
      </div>
      <div className="cookie-general">
        <h3>2. COOKIES</h3>
        <ul>
          <li>
            Cookies are a small piece of data stored on your device when you
            visit the Site. Cookies can be "persistent" and "session" cookies.
            Session cookies are deleted after you close the browser. Persistent
            cookies (long-term cookies) remain on your computer and permit their
            recognition on your next visit. This allows us to improve your
            access to our Site. This helps us to learn more about your
            interests, and provide you with essential features and services.
          </li>
          <li>
            Following are the types of cookies we use on our Website:
            <ul>
              <li>
                {" "}
                - Required cookies: These cookies are essential for our Websites
                and service to perform basic functions and are necessary for us
                to operate certain features. These include those required to
                allow registered users to authenticate and perform account
                related functions, store preference set by users.
              </li>
              <li>
                {" "}
                - Functionality cookies: These cookies allow Us to remember
                choices you make (such as your username, language or the region
                you are in) and provide more enhanced personal features. These
                cookies can not track your browsing activity on other Websites.
              </li>
              <li>
                {" "}
                - Analytics and Performance Cookies: These cookies allow Us to
                optimize performance by collecting information on how users
                interact with our Site, including which pages are visited most,
                as well as other analytical data. We use these details to
                improve how our Site functioning and to understand how users
                interact with them.
              </li>
            </ul>
          </li>
          <li>
            {" "}
            The cookies we use do not collect any personal data stored on your
            hard drive or computer.
          </li>
        </ul>
      </div>
      <div className="cookie-general">
        <h3>3. HOW WE USES COOKIES</h3>
        <ul>
          <li>
            When you use and access the Website, we may place a number of
            cookies files in your browser.
          </li>
          <li>
            We use the cookies for the following purposes:
            <ul>
              <li>- To enable certain functions of the Site.</li>
              <li>
                - Conducting research and diagnostics to improve the content,
                products, and services
              </li>
              <li>- Preventing fraudulent activity.</li>
              <li>- Improving security.</li>
            </ul>
          </li>
          <li>
            Our cookies allow you to take advantage of some of our essential
            features. For instance, if you block or otherwise reject our
            cookies, you will not be able to use any products or services that
            require you to sign in.
          </li>
        </ul>
      </div>
      <div className="cookie-general">
        <h3>4. SOCIAL MEDIA COOKIES</h3>
        <ul>
          <li>
            This Website incorporates plugins and/or buttons for social
            networks, in order to allow easy sharing on your favourite social
            networks.
          </li>
          <li>
            These plugins are programmed so as not to set any cookies when
            assessing the page to safeguard the privacy of users. Cookies may be
            set, if you make voluntary use of the plugin.
          </li>
          <li>
            The collection and use of information obtained by means of the
            plugin are governed by the respective privacy policies of the
            concerned social networks and We have no control or liability over
            these cookies.
          </li>
        </ul>
      </div>
      <div className="cookie-general">
        <h3>5. CONSENT FOR COOKIES</h3>
        <p>
          Generally, we ask you to consent to the use of cookies on this Site.
          But, in the case of required cookies, consent may not be asked and it
          will be implied that you have consented to the use of the same.
        </p>
      </div>
      <div className="cookie-general">
        <h3>6. CHANGES TO THIS POLICY</h3>
        <p>
          {" "}
          We may change this policy from time to time. If we make any changes to
          this policy, we will change the "Last Updated" date above. You agree
          that your continued use of our services after such changes have been
          published to our services will constitute your acceptance of such
          revised policy.
        </p>
      </div>
      <div className="cookie-general">
        <h3>7. YOUR CHOICES REGARDING COOKIES</h3>
        <p>
          You can prevent the storage of cookies by choosing a "disable cookies"
          option in your browser settings. Most browsers allow you to manage how
          cookies are set and used as you're browsing, and to clear cookies and
          browsing data. Some browsers allow controlling the functionality such
          as the length of time they are stored either through built-in
          functionality or by utilizing third-party plugins. But this can limit
          the functionality of our services.
        </p>
      </div>
      <div className="cookie-contact-us">
        <h3>8. CONTACT US</h3>
        <p>
          In case you have any suggestions, queries or complains, please contact
          Us using the following details: contact@fortifai.io
        </p>
      </div>
    </section>
  );
}

export default CookiePolicy;
