import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Solutions from "./components/Solutions/Solutions";
import KeyFeatures from "./components/KeyFeatures/KeyFeatures";
import AboutCompany from "./components/AboutTheCompany/Company";
import Footer from "./components/Footer/Footer";
import BookDemo from "./components/BookDemo/BookDemo";
import Privacy from "./components/Footer/PrivacyPolicy";
import Security from "./components/Solutions/Security";
import CookiePolicy from "./components/Footer/CookiePolicy/CookiePolicy";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import Pricing from "./components/Pricing/Pricing";
import Insights from "./components/Resources/Insight";
import Blog from "./components/Blog/Blog";
import GenAIBlog from "./components/Resources/GenAIBlog";
import Report from "./components/Resources/Reports/Report";
import PreinvestmentForensicDueDiligence from "./components/Resources/PreinvestmentForensicDueDiligence";
import ProblemSolving from "./components/Solutions/ProblemSolving/ProblemSolving";
import ProductInformation from "./components/Resources/ProductInformation/ProductInformation";
import ContactUs from "./components/ContactUs/ContactUs";
import { Helmet } from "react-helmet-async";
import Investigation from "./components/Investigation/Investigation";
import Fraud101 from "./components/Resources/Articles/Fraud101";
import ExecutiveMalfeasance from "./components/Resources/Articles/ExecutiveMalfeasance";
import WorkflowAutomation from "./components/Resources/Articles/WorkflowAutomation";
import DataFoundation from "./components/Solutions/DataFoundation/foundation";
import DemoVideos from "./components/DemoVideos/DemoVideos";
function App() {
  const location = useLocation();

  const { setPathPageView, setTrackPageView } = useTrackingCode();

  useEffect(() => {
    setPathPageView(location.pathname);
    setTrackPageView();
  }, [location.pathname, setPathPageView, setTrackPageView]);

  return (
    <>
      <div className="App">
        <Helmet>
          <title>FortifAI</title>
          <meta property="og:title" content="FortifAI" />
          <meta
            property="og:description"
            content="End to end software platform for fraud prevention"
          />
          <meta
            name="description"
            content="End to end software platform for fraud prevention"
          />
        </Helmet>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/key-features" element={<KeyFeatures />} />
          <Route path="/demo-videos" element={<DemoVideos />} />
          <Route path="/about" element={<AboutCompany />} />
          <Route path="/meeting" element={<BookDemo />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/security" element={<Security />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/reports" element={<Report />} />
          <Route path="/blog/gen-ai-fraud-detection" element={<GenAIBlog />} />
          <Route
            path="/blog/pre-investment-forensic-due-diligence"
            element={<PreinvestmentForensicDueDiligence />}
          />
          <Route path="/problem-solving" element={<ProblemSolving />} />
          <Route path="/product-information" element={<ProductInformation />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/investigation" element={<Investigation />} />
          <Route path="/data-foundation" element={<DataFoundation />} />
          <Route path="/blog/fraud-101" element={<Fraud101 />} />
          <Route
            path="/blog/executive-malfeasance"
            element={<ExecutiveMalfeasance />}
          />
          <Route path="/workflow-automation" element={<WorkflowAutomation />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
