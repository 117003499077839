import React, { useState } from "react";
import "./Navbar.scss";
import Logo from "../assets/logo.svg";
import fortifai_logo from "../assets/fortifai_logo.png";
import Menu from "../assets/Menu.png";
import EmailIcon from "../assets/EmailIcon.png";
import Cancel from "../assets/Cancel.png";
import LocationIcon from "../assets/LocationIcon.png";
import PhoneIcon from "../assets/PhoneIcon.png";
import Dropdown from "../assets/Dropdown.svg";
import ArrowUp from "../assets/arrowup.svg";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Forward from "../assets/forward.svg";

const Navbar = () => {
  const navigate = useNavigate();

  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [resourceDropDown, setresourceDropDown] = useState(false);

  const handleBookDemo = () => {
    navigate("/meeting");
    setShowHamburgerMenu(false);
  };

  const handleHamburgerMenu = () => {
    setShowHamburgerMenu(!showHamburgerMenu);
  };

  const handleLinkClick = () => {
    setShowHamburgerMenu(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleResourceDropdown = () => {
    setresourceDropDown(!resourceDropDown);
  };

  const location = useLocation();

  return (
    <div className="navbar">
      {!showHamburgerMenu && (
        <>
          <div className="logo">
            <img src={Logo} alt="" id="logo-img" />
            {/* <span>FORTIF</span> */}
            <img src={fortifai_logo} alt="" id="AI" />
          </div>
          <div className="menu">
            <ul>
              <li className="dropdown">
                <a
                  href="/"
                  style={{
                    borderBottom:
                      location.pathname === "/" ? "2px solid #003761" : "",
                  }}
                >
                  Home
                </a>
              </li>

              <div className="dropdown">
                <div
                  style={{
                    fontSize: "1.1rem",
                    fontFamily: "Axiforma",
                    color: "#003761",
                    fontWeight: 600,
                    textDecoration: "none",
                    borderBottom:
                      location.pathname === "/solutions" ||
                      location.pathname === "/key-features" ||
                      location.pathname === "/problem-solving" ||
                      location.pathname === "/investigation" ||
                      location.pathname === "/demo-videos"
                        ? "2px solid #003761"
                        : "",
                  }}
                >
                  Solutions{" "}
                </div>
                {/* <Link
                    to="/solutions"
                    onClick={scrollToTop}
                    style={{
                      borderBottom:
                        location.pathname === "/solutions"
                          ? "2px solid #003761"
                          : "",
                    }}
                  >
                    Solutions
                  </Link> */}
                <div className="dropdown-content">
                  <ul>
                    <li>
                      <a href="/solutions">
                        FortifAI
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                    <li>
                      <a href="/key-features">
                        Key Features{" "}
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                    <li>
                      <a href="/problem-solving">
                        Problem We Solve
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                    <li>
                      <a href="/investigation">
                        Investigation Case Management
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                    <li>
                      <a href="/data-foundation">
                        Data Foundation
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                    <li>
                      <a href="/demo-videos">
                        FortifAI Demo Videos
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <li className="dropdown">
                <a
                  href="/pricing"
                  style={{
                    borderBottom:
                      location.pathname === "/pricing"
                        ? "2px solid #003761"
                        : "",
                  }}
                >
                  Pricing
                </a>
              </li>

              <div className="dropdown">
                <div
                  style={{
                    fontSize: "1.1rem",
                    fontFamily: "Axiforma",
                    color: "#003761",
                    fontWeight: 600,
                    borderBottom:
                      location.pathname === "/insights" ||
                      location.pathname === "/reports" ||
                      location.pathname === "/product-information"
                        ? "2px solid #003761"
                        : "",
                  }}
                >
                  Resources
                </div>
                {/* <Link
                    to="/solutions"
                    onClick={scrollToTop}
                    style={{
                      borderBottom:
                        location.pathname === "/solutions"
                          ? "2px solid #003761"
                          : "",
                    }}
                  >
                    Solutions
                  </Link> */}
                <div className="dropdown-content">
                  <ul>
                    <li>
                      <a href="/insights">
                        Insights
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                    <li>
                      <a href="/reports">
                        Reports
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                    <li>
                      <a href="/product-information">
                        Product Information
                        <img src={Forward} alt="Arrow" className="drop" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <li className="dropdown">
                <a
                  href="/about"
                  style={{
                    borderBottom:
                      location.pathname === "/about" ? "2px solid #003761" : "",
                  }}
                >
                  About Us
                </a>
              </li>
              <li className="dropdown">
                <a
                  href="/contact-us"
                  style={{
                    borderBottom:
                      location.pathname === "/contact-us"
                        ? "2px solid #003761"
                        : "",
                  }}
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <button className="demo-button" onClick={() => handleBookDemo()}>
              Schedule a Demo
            </button>
            <div
              className="hamburger-menu"
              onClick={() => handleHamburgerMenu()}
            >
              <img className="menu-image" src={Menu} alt="" />
            </div>
          </div>
        </>
      )}
      {showHamburgerMenu && (
        <div className="humburger-menu-content">
          <div className="navbar-menu">
            <div className="logo">
              <img src={Logo} alt="" id="logo-img" />
              {/* <span>FORTIF</span> */}
              <img src={fortifai_logo} alt="" id="AI" />
            </div>
            <div className="menu">
              <button className="demo-button" onClick={() => handleBookDemo()}>
                Schedule a Demo
              </button>
              <div
                className="hamburger-menu"
                onClick={() => handleHamburgerMenu()}
              >
                <img className="cancel-img" src={Cancel} alt="" />
              </div>
            </div>
          </div>
          <div className="menu-options">
            <div className="options">
              <ul>
                <li className="dropdown">
                  <a
                    style={{
                      borderBottom:
                        location.pathname === "/" ? "2px solid #003761" : "",
                    }}
                    href="/"
                    onClick={() => {
                      handleLinkClick();
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="dropdown">
                  <div
                    style={{
                      fontSize: "1.1rem",
                      fontFamily: "Axiforma",
                      color: "#003761",
                      fontWeight: 600,
                      borderBottom:
                        location.pathname === "/solutions" ||
                        location.pathname === "/key-features" ||
                        location.pathname === "/problem-solving" ||
                        location.pathname === "/demo-videos" ||
                        location.pathname === "/investigation" ||
                        location.pathname === "/data-foundation"
                          ? "2px solid #003761"
                          : "",
                    }}
                    onClick={toggleDropdown}
                  >
                    Solutions
                  </div>
                  {/* <Link
                    to="/solutions"
                    onClick={() => {
                      scrollToTop();
                      handleLinkClick();
                    }}
                  >
                    Solutions
                  </Link> */}
                  <img
                    src={Dropdown}
                    alt="Dropdown"
                    className="dropdown-icon"
                    onClick={toggleDropdown}
                  />
                </li>
                {dropdownOpen && (
                  <div className="dropdown-menu">
                    <a
                      href="/solutions"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      FortifAI
                    </a>
                    <a
                      href="/key-features"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      Key Features
                    </a>
                    <a
                      href="/problem-solving"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      Problem We Solve
                    </a>
                    <a
                      href="/investigation"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      Investigation Case Management
                    </a>
                    <a
                      href="/data-foundation"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      Data Foundation
                    </a>
                    <a
                      href="/demo-videos"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      FortifAI Demo Videos
                    </a>
                  </div>
                )}
                <li className="dropdown">
                  <a
                    style={{
                      borderBottom:
                        location.pathname === "/Pricing"
                          ? "2px solid #003761"
                          : "",
                    }}
                    href="/Pricing"
                    onClick={() => {
                      handleLinkClick();
                    }}
                  >
                    Pricing
                  </a>
                </li>
                <li className="dropdown">
                  <Link
                    // onClick={() => {
                    //   handleLinkClick();
                    // }}
                    onClick={toggleResourceDropdown}
                    style={{
                      borderBottom:
                        location.pathname === "/insights" ||
                        location.pathname === "/reports" ||
                        location.pathname === "/product-information"
                          ? "2px solid #003761"
                          : "",
                    }}
                  >
                    Resources
                  </Link>
                  <img
                    src={Dropdown}
                    alt="Dropdown"
                    className="dropdown-icon"
                    onClick={toggleResourceDropdown}
                  />
                </li>
                {resourceDropDown && (
                  <div className="dropdown-menu">
                    <a
                      href="/insights"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      Insights
                    </a>
                    <a
                      href="/reports"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      Reports
                    </a>
                    <a
                      href="/product-information"
                      onClick={() => {
                        handleLinkClick();
                        toggleDropdown();
                      }}
                    >
                      Product Information
                    </a>
                  </div>
                )}
                <li className="dropdown">
                  <a
                    href="/about"
                    onClick={() => {
                      handleLinkClick();
                    }}
                    style={{
                      borderBottom:
                        location.pathname === "/about"
                          ? "2px solid #003761"
                          : "",
                    }}
                  >
                    About Us
                  </a>
                </li>
                <li className="dropdown">
                  <a
                    style={{
                      borderBottom:
                        location.pathname === "/security"
                          ? "2px solid #003761"
                          : "",
                    }}
                    href="/security"
                    onClick={() => {
                      handleLinkClick();
                    }}
                  >
                    Security
                  </a>
                </li>
                <li className="dropdown">
                  <a
                    style={{
                      borderBottom:
                        location.pathname === "/contact-us"
                          ? "2px solid #003761"
                          : "",
                    }}
                    href="/contact-us"
                    onClick={() => {
                      handleLinkClick();
                    }}
                  >
                    Contact Us
                  </a>
                </li>
                <li className="dropdown">
                  <a
                    style={{
                      borderBottom:
                        location.pathname === "/privacy-policy"
                          ? "2px solid #003761"
                          : "",
                    }}
                    href="/privacy-policy"
                    onClick={() => {
                      handleLinkClick();
                    }}
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="dropdown">
                  <a
                    style={{
                      borderBottom:
                        location.pathname === "/cookies"
                          ? "2px solid #003761"
                          : "",
                    }}
                    href="/cookies"
                    onClick={() => {
                      handleLinkClick();
                    }}
                  >
                    Cookie Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="demo-booking">
              <span>Are you ready to start your</span>
              <span>fraud prevention journey?</span>
              <button onClick={() => handleBookDemo()}>Book a Demo</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
