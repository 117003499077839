import React from "react";
import "./Solutions.scss";
import Feature1 from "../assets/feature1.svg";
import Feature2 from "../assets/feature2.svg";
import Feature3 from "../assets/feature3.svg";
import Feature4 from "../assets/feature4.svg";
import Feature5 from "../assets/feature5.svg";
import Feature6 from "../assets/feature6.svg";
import { useNavigate } from "react-router-dom";
function KeyFeatures() {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleKeyClick() {
    scrollToTop();
    navigate("/key-features");
  }
  return (
    <section>
      <section className="solution-page-key-features">
        <h2>Key Features</h2>
        <div className="content">
          <div className="unit">
            <img src={Feature1} alt="" />
            <p>Hundreds of pre-configured fraud risk scenarios to combat fraud</p>
          </div>
          <div className="unit" id="left-right-border">
            <img src={Feature2} alt="" />
            <p>Powerful false positive management engine. Powered by AI.</p>
          </div>
          <div className="unit" id="bottom-border">
            <img src={Feature3} alt="" />
            <p>Powerful and independent 3rd party background search feature</p>
          </div>
          <div className="unit" id="top-border">
            <img src={Feature4} alt="" />
            <p>A powerful investigation module that facilitates in-app investigation</p>
          </div>
          <div className="unit" id="left-right-top-border">
            <img src={Feature5} alt="" />
            <p>Easily integrates with any data systems as per your convenience</p>
          </div>
          <div className="unit" id="mobile-view-border">
            <img src={Feature6} alt="" />
            <p>
            Fully automated one-click 
“Download Report” with full evidentiary value
            </p>
          </div>
        </div>
        <button className="features-button" onClick={handleKeyClick}>
          Learn more about our Key Features
        </button>
      </section>
    </section>
  );
}

export default KeyFeatures;
