import "./Report.scss";
import report_arrow from "../../assets/report_arrow.png";
const Report = () => {
  return (
    <>
      <section className="report-heading-container">
        <div className="center-div">
          <h2>Reports</h2>
          <p>Resources &gt; Reports</p>
        </div>
      </section>
      <div className="report-container">
        {/* <div className="report-container-title">Fraud Articles</div> */}
        <div className="report-container-content">
          <div className="report-content-link">
            <a
              href="https://fortifai.blob.core.windows.net/fortifai/2024-Report-to-the-Nations.pdf"
              className="a-tag"
              target="_blank"
              rel="noreferrer" 
            >
              2024 Report on Occupational Fraud{" "}
              <img
                className="report-arrow-image"
                src={report_arrow}
                alt="arrow"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
