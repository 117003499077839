import "./Blog.scss";
const Blog = (props) => {
  const { blogData } = props;
  // const blogData = {
  //   title: "Generative AI for Fraud Detection",
  //   subtitle: "Insights",
  //   topText: [
  //     "In the fast-paced world of fintech, Generative AI has rapidly become a key player in the fight against cyberattacks and not only them. This technology, far beyond traditional data analysis, is actively shaping the future of fraud detection.",
  //     "Did you know that US fintech companies are currently facing significant losses to fraud, with an average of $51 million lost annually, representing about 1.7% of total revenues? This stark reality underscores the critical need for more advanced and effective prevention strategies​​.",
  //     "Generative AI for fraud detection offers a promising solution, but it also comes with some challenges. While holding the power to identify and anticipate complex fraudulent schemes, its sophisticated capabilities also open doors for misuse.",
  //     "In this article, we will set the stage for a comprehensive exploration of how Gen AI is being leveraged in fraud detection, highlighting its potential to transform security measures across industries. Let’s embark on a journey through the intricate dance of technology, strategy, and survival in the face of ever-evolving digital threats. Read to the end to gain valuable insights into how businesses can benefit from the advantages of Generative AI to protect their operations and customers.",
  //   ],
  //   listText: {
  //     title: "Evolution of Fraud Detection Approaches and Tools",
  //     subText: [
  //       "The development of such technologies has been a dynamic and evolving process, mirroring the escalating sophistication of financial deception. This progression can be segmented into various stages, each characterised by notable technological advancements and shifts in approach.",
  //     ],
  //     listText: [
  //       {
  //         boldText: "1990s : ",
  //         text: "Foundational Years with Simple Rules Engines: The fraud detection journey began with simple rules-based engines. These systems used predefined guidelines to detect threats but struggled with complexity and required frequent updates",
  //       },
  //       {
  //         boldText: "Early 2000s : ",
  //         text: "Rise of Neural Networks and Statistical Models: Advancements in AI led to the integration of neural networks and statistical models. These technologies delved into historical data to uncover complex fraud patterns that were not detectable by simple rules engines​​. ",
  //       },
  //       {
  //         boldText: "First : ",
  //         text: "Generation Systems Limitations: The initial systems were rigid and prone to false positives. They were heavily reliant on manual processes, making them time-consuming and not scalable​​.",
  //       },
  //     ],
  //   },
  //   listText2: {
  //     title: "Generative AI for Fraud Detection: Benefits and Mechanisms",
  //     subText: [
  //       "As we have already mentioned above, Gen AI has become a pivotal tool for detecting and preventing fraudulent activity, offering several advantages and instruments that traditional methods lacked. Its application is marked by adaptive learning, data augmentation, and sophisticated algorithms, leading to significant improvements in precision and a reduction in false positives.",
  //       "According to the Arab Monetary Fund Study (AMF), machine learning models can catch up to 94% of fraudulent transactions in real-time, significantly reducing financial losses for enterprises. And this is just the beginning! Let’s dive deeper into the real business benefits the technology holds for global markets."
  //     ],
  //     listText: [
  //       {
  //         boldText: "Real-Time Analysis and Dynamic Anomaly Detection : ",
  //         text: "Generative AI excels in real-time inspection of vast amounts of datasets. This capability is crucial in industries like finance and eСommerce, where transactions occur in high volumes. By processing this data instantly, artificial intelligence can flag or block suspicious financial activities as they happen, thereby preventing potential economic loss. It can recognize ‘normal’ behavior based on historical data and immediately flag deviations from this norm. This is a far more effective approach than previous systems were capable of.",
  //       },
  //       {
  //         boldText: "Adaptive Learning : ",
  //         text: "Unlike traditional systems that rely on static rules and models, Generative AI can learn and adapt from the data they process. They are capable of evolving to recognize new types of fraud as they emerge, often without the need for manual intervention. This adaptability is crucial for staying ahead of fraudsters who continually evolve their tactics​​.",
  //       },
  //       {
  //         boldText: "Data Augmentation and Improved ML : ",
  //         text: "Generative AI is particularly effective in creating synthetic datasets based on real data. This capability is valuable in fraud detection where viable examples are often limited, making it challenging for ML models to learn effectively. By generating synthetic samples that resemble real-life cases, Gen AI boosts the attention signal for core detection tools. This approach adds robustness to the deception model, enabling it to detect not only patterns but also similar attacks that could be missed using traditional methods​​​​.",

  //       },
  //       {
  //         boldText: "Synthetic Data Generation for Model Training : ",
  //         text: "Generative AI is also adept at unsupervised learning, a technique that allows the model to learn from unlabelled data. This is particularly useful in fraud detection, where the data is often unstructured and lacks clear patterns. By analyzing this data, Gen AI can identify hidden connections and anomalies that might indicate fraudulent activities​​.",
  //       },
  //       {
  //         boldText: "Combating AI-Enabled Fraud : ",
  //         text: " As fraudsters also use Gen AI for creating deepfakes, deceptive content, and synthetic identities, businesses must stay vigilant and adapt their security strategies. Using Generative AI for fraud detection and prevention helps analyze patterns in data and identify potential risk factors. This approach enables companies to spot early indicators of possible fraudulent behavior and speed up the development and testing of new detection models",
  //       }
  //     ],
  //   },
  //   paraText:{
  //     title: "Real-World Applications and Successful Business Outcomes",
  //     subText: [
  //       "Generative AI for banking helps with analyzing trends in stock markets and leveraging machine learning for more efficient threat detection. This technological advancement has proven financially beneficial for major institutions. Jamie Dimon, the CEO of JPMorgan Chase, noted in 2019 that these innovations have resulted in annual savings of about $150 million for the bank.",
  //       "PayPal has leveraged Gen AI and ML to enhance its fraud detection capabilities. Their approach has led to impressive results, including a substantial reduction in losses. From 2019 to 2022, PayPal almost halved its loss rate, even as its annual payment volumes nearly doubled from $712 billion to $1.36 trillion. This achievement can be attributed to PayPal’s advances in AI algorithms and technology, which allow rapid adaptation to changing deception patterns and protect customers more effectively.",
  //       "American Express, a major player in the financial industry, has implemented generative modeling techniques to combat credit card fraud. By producing synthetic data, such as fake card numbers, and monitoring for discrepancies or similarities with actual fraudulent transactions, American Express has been able to upgrade its strategies​ and remain competitive in 2023."
  //     ],
  //   },
  //   paraText2:{
  //     title: "Conclusion",
  //     subText: [
  //       "The integration of Generative AI for fraud detection purposes has brought about remarkable advancements, from real-time analysis to the development of sophisticated, adaptive learning models. These technologies have proven essential in the proactive identification and prevention of fraudulent activities, significantly reducing financial losses for businesses.",
  //       "Being a leading Generative AI development company, Master of Code Global stands out for its innovative approach and commitment to delivering cutting-edge AI solutions. With a focus on crafting exceptional digital experiences, we combine expertise in AI, machine learning, and natural language processing to bring transformative apps to enterprises across various industries.",
  //       "By continuously embracing new challenges and pushing the boundaries of technology, MOCG is not just adapting to the rapidly changing digital landscape, but actively shaping it. Partnering with us to level up your fraud detection and prevention strategy with bespoke AI solutions."
  //     ],
  //   }
  // };
  return (
    <>
      {blogData && (
        <div className="blogs-text-container">
          <section className="blogs-text-header-container">
            <div className="center-div">
              <h2>{blogData.title}</h2>
              <p>
              <a href="/insights" style={{textDecoration:'none',color: '#54565A'}}>{blogData.subtitle}</a> &gt; {blogData.title}
              </p>
            </div>
          </section>
          {/* <div className="blog-top-heading-container">
        <div className="blog-top-heading-title-container">
          <div className="blog-top-heading-title">
            {blogData.title}
            </div>
          <div className="blog-top-heading-subtitle">
            {blogData.subtitle} {">"} {blogData.title}
          </div>
        </div>
      </div> */}
          <div className="blog-top-text-container">
            {blogData.topText.map((text) => {
              return <div className="blog-top-text">{text}</div>;
            })}
          </div>
          <div className="blog-list-paragraph-container">
            <div className="blog-list-title">{blogData.listText?.title}</div>
            {blogData.listText.subText.map((text) => {
              return <div className="blog-list-title-paragraph">{text}</div>;
            })}
            {blogData.listText.listText.map((text) => {
              return (
                <>
                  {text?.isImage == true ? (
                    <>{text.imageJSX}</>
                  ) : (
                    <>
                      <ul className="blog-list-item-paragraph">
                        <li>
                          <span className="blog-list-bold-text">
                            {text.boldText}
                          </span>
                          <span>{text.text}</span>
                        </li>
                      </ul>
                    </>
                  )}
                </>
              );
            })}
          </div>
          <div className="blog-list-paragraph-container">
            <div className="blog-list-title">{blogData.listText2.title}</div>
            {blogData?.listText2?.subText.map((text) => {
              return <div className="blog-list-title-paragraph">{text}</div>;
            })}
            {blogData.listText2.listText.map((text) => {
              return (
                <ul className="blog-list-item-paragraph">
                  <li>
                    <span className="blog-list-bold-text">{text.boldText}</span>
                    <span>{text.text}</span>
                  </li>
                </ul>
              );
            })}
          </div>
          <>
            {blogData?.listText3 && (
              <div className="blog-list-paragraph-container">
                <div className="blog-list-title">
                  {blogData.listText3.title}
                </div>
                {blogData.listText3.subText.map((text) => {
                  return (
                    <div className="blog-list-title-paragraph">{text}</div>
                  );
                })}
                {blogData.listText3.listText.map((text) => {
                  return (
                    <ul className="blog-list-item-paragraph">
                      <li>
                        <span className="blog-list-bold-text">
                          {text.boldText}
                        </span>
                        <span>{text.text}</span>
                      </li>
                    </ul>
                  );
                })}
                {blogData?.listText3?.lastParaText?.map((text) => {
                  return (
                    <div className="blog-list-title-paragraph">{text}</div>
                  );
                })}
              </div>
            )}
          </>
          <>
            {blogData?.listText4 && (
              <div className="blog-list-paragraph-container">
                <div className="blog-list-title">
                  {blogData.listText4.title}
                </div>
                {blogData.listText4.subText.map((text) => {
                  return (
                    <div className="blog-list-title-paragraph">{text}</div>
                  );
                })}
                {blogData.listText4.listText.map((text) => {
                  return (
                    <ul className="blog-list-item-paragraph">
                      <li>
                        <span className="blog-list-bold-text">
                          {text.boldText}
                        </span>
                        <span>{text.text}</span>
                      </li>
                    </ul>
                  );
                })}
                {blogData?.listText4?.lastParaText?.map((text) => {
                  return (
                    <div className="blog-list-title-paragraph">{text}</div>
                  );
                })}
              </div>
            )}
          </>

          <div className="blog-paragraph-with-title-container">
            <div className="blog-paragraph-title">
              {blogData.paraText.title}
            </div>
            {blogData.paraText.subText.map((text) => {
              return <div className="blog-paragraph">{text}</div>;
            })}
          </div>
          <div className="blog-paragraph-with-title-container">
            <div className="blog-paragraph-title">
              {blogData.paraText2.title}
            </div>
            {blogData.paraText2.subText.map((text) => {
              return <div className="blog-paragraph">{text}</div>;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Blog;
