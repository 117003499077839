import React from "react";
import PropTypes from "prop-types";
import "./Insight.scss";
import Arrow from "../assets/forwardarrow.svg";
const BlogCard = ({ imageUrl, date, heading, description, link }) => {
  return (
    <a style={{ textDecoration: "none" }} href={link} className="blog-card">
      <img src={imageUrl} alt="Blog Cover" className="blog-image" />
      <div className="blog-info">
        <div className="blog-date">Published on {date}</div>
        <h2 className="blog-heading">{heading}</h2>
        <p className="blog-description">{description}</p>
        <div className="read-more">
          <a href={link}>Read More</a>
          <img src={Arrow} alt="" />
        </div>
      </div>
    </a>
  );
};

BlogCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default BlogCard;
