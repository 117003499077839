import React from "react";
import "./Company.scss";
import AboutUsImage from "../assets/ABOUTUSRIGHT.png";
import Mission from "./Mission";
import Founders from "./Founders";
// import AdvisoryBoard from "./AdvisoryBoard/AdvisoryBoard";
function Company() {
  const scrollToFounders = () => {
    const foundersPage = document.getElementById("founders-page");
    if (foundersPage) {
      foundersPage.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section>
      <section className="company-details">
        <div className="center-div">
          <div className="left-container">
            <h2>About Us</h2>
            <p>
              {/* Transforming fraud risk management with proactive digitization and
              automation. */}
              We are on a mission to digitise and automate the fraud risk
              management process and make it preventive.
            </p>
            <button onClick={scrollToFounders}>Know About Our Founders</button>
          </div>
          <div className="right-container">
            {/* <div className="text">
              <span>Driving</span>
              <span>Enterprise Value</span>
              <span>Through Governance</span>
            </div> */}

            <img src={AboutUsImage} alt="" className="about-us-image" />
          </div>
        </div>
      </section>
      <Mission />
      <Founders />
      {/* <AdvisoryBoard/> */}
    </section>
  );
}

export default Company;
