import React from "react";

function Mission() {
  return (
    <section className="mission">
      <h2>OUR MISSION</h2>
      <h1>
        {/* We are on a mission to digitise and automate the fraud risk management
        process and make it preventive. */}
        Fraud Risk Management value chain is broken. It is structured around
        investigating fraud after the fact, rather than preventing it.
      </h1>

      <div className="mission-content">
        <div className="mission-unit-heading">
          {/* <h3>Unlocking Potential</h3> */}

          {/* <p>
            “Fraud Risk Management value chain is broken. It is structured
            around investigating fraud after the fact, rather than preventing
            it.”{" "}
          </p> */}
          {/* <p>
            At FortifAI.io, we're not just revolutionizing fraud risk
            management; we're reshaping its very essence. Our journey begins
            with a bold mission: to digitize and automate the fraud risk
            management process, turning it from reactive to preventive.
          </p> */}
        </div>
        {/* <div className="parent-separator">
          <div className="short-blue-separator"></div>
          <div className="separator"></div>
          <div className="short-blue-separator"></div>
        </div> */}
        <div className="mission-unit">
          {/* <h3>Breaking Chains, Building Solutions</h3> */}
          {/* <p>
            Fraud risk management relies on outdated, reactive approaches,
            neglecting proactive prevention. Less than 5% of frauds are detected
            through surveillance or automation, causing delays and significant
            financial losses.
          </p> */}
          <p>
            The fraud risk management value chain is broken. Currently, less
            than 5% of frauds are detected through any kind of surveillance or
            automated mechanism. Entire fraud risk management is post-mortem.
            Frauds are detected and investigated after the fact. On average, 6
            to 9 months have elapsed before a fraud is detected. This results in
            a revenue loss of up to 5% with a median value of fraud being USD
            100,000 +.{" "}
          </p>
        </div>
        {/* <div className="parent-separator">
          <div className="short-blue-separator"></div>
          <div className="separator"></div>
          <div className="short-blue-separator"></div>
        </div> */}
        <div className="mission-unit">
          {/* <h3>FortifAI.io : A Catalyst for Change</h3> */}
          {/* <p>
            Innovating fraud risk management with real-time transaction
            detection. Empower your team to prevent fraud, optimize controls,
            and gain a strategic edge. Join us in shaping a safer, smarter
            future.
          </p> */}
          <p>
            FortifAI.io is on a mission to change all this. Our proprietary SAAS
            platform automates suspicious transaction detection at an
            organisation level. This enables the Internal Audit teams and
            Management Assurance professionals to focus on taking action before
            the fraud takes place. Automated insights generated by FortifAI also
            help them focus on systems and control improvement.{" "}
          </p>
        </div>
        {/* <div className="parent-separator">
          <div className="short-blue-separator"></div>
          <div className="separator"></div>
          <div className="short-blue-separator"></div>
        </div> */}
      </div>
    </section>
  );
}

export default Mission;
