import { useEffect } from "react";
import "./ContactUs.scss";
const ContactUs = () => {
  return (
    <>
      <section className="contact-us-heading-container">
        <div className="center-div">
          <h2>Contact Us</h2>
          {/* <p>Resources &gt; Reports</p> */}
        </div>
      </section>
      <div className="hubspot-form-container">
        <HubSpotForm />
      </div>
    </>
  );
};

const HubSpotForm = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/v2.js';
      script.async = true;
      document.body.appendChild(script);
  
      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: '45817387',
            formId: '229facf4-e94a-4f08-8ed5-2a2fa08aa9cf',
            target: '#hubspot-form'
          });
        }
      };
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    return <div id="hubspot-form"></div>;
  };

export default ContactUs;
