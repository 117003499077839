import './ProductInformation.scss';
import report_arrow from "../../assets/report_arrow.png";
const ProductInformation = () => {
    return (
        <>
        <section className="product-information-heading-container">
        <div className="center-div">
          <h2>Product Information</h2>
          <p>Resources &gt; Product Information</p>
        </div>
      </section>
      <div className="product-information-container">
        {/* <div className="product-information-container-title">Fraud Articles</div> */}
        <div className="product-information-container-content">
          <div className="product-information-content-link">
            <a
              href="https://fortifai.blob.core.windows.net/fortifai/Fortifai Fraud Prevention SAAS.pdf"
              className="a-tag"
              target="_blank"
              rel="noreferrer" 
            >
              Fortifai Fraud Prevention SaaS{" "}
              <img
                className="product-information-arrow-image"
                src={report_arrow}
                alt="arrow"
              />
            </a>
          </div>
        </div>
      </div>
        </>
    )
}

export default ProductInformation;