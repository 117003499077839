import React from "react";
import "./Investigation.scss";
import { useNavigate } from "react-router-dom";

export default function Investigation() {
  const navigate = useNavigate();
  const handleBookDemo = () => {
    navigate("/meeting");
  };
  return (
    <>
      <section className="investigation-container">
        <div className="center-div">
          <h2>Investigation Case Management</h2>
          <p>
            Digitize and automate your internal investigations. Introducing the
            most powerful investigation case management module ever!
          </p>
          <div className="demo-button">
            <button onClick={() => handleBookDemo()}>Schedule a Demo</button>
          </div>
        </div>
      </section>
      <section className="investigation-process">
        <div className="main-heading">
          Assign Cases, Investigate, Review, Recommend Action, Download Reports
          And Track Performance. All Under One Platform!
        </div>
        <div className="sub-heading">
          An industry-first, fully digitized internal fraud incident management
          platform
        </div>
        <div className="case-grid">
          <div className="blue-box">
            <span>
              Automated suspicious transaction pipeline development through
              FortifAI or your whistleblower portal
            </span>
          </div>
          <div className="yellow-box">
            <span>
              Leverage FortifAI’s whistleblower integration capability to
              onboard all WB complaint for investigations
            </span>
          </div>
          <div className="blue-box">
            <span>
              Assign identified risks to internal or external investigators
            </span>
          </div>
          <div className="yellow-box">
            <span>
              Record investigation methodology, manage investigation
              workflow/process, upload evidence and data, write reports, review
              and approve, download report – all in FortifAI
            </span>
          </div>
          <div className="blue-box">
            <span>
              Full access to external database for 3rd part of due-diligence and
              background checks
            </span>
          </div>
          <div className="yellow-box">
            <span>
              Powerful performance dashboard to track critical investigation
              related KPI’s
            </span>
          </div>
        </div>
        <div className="demo">
          <button onClick={() => handleBookDemo()}>Schedule a Demo</button>
        </div>
      </section>
    </>
  );
}
