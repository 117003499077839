import './DemoVideos.scss';
const DemoVideos = () => {
    return (
        <>
         <section className="demo-videos-heading-container">
        <div className="center-div">
          <h2>FortifAI Demo Videos</h2>
          <p>
            Gain valuable insights into our platform's capabilities with this comprehensive demo videos
          </p>
          {/* <button>
            <a className="a-tag" href="/meeting">
              Schedule a Demo
            </a>
          </button> */}
         
               
        </div>
      </section>
      <div className='demo-video-container'>
      <video controls poster="https://fortifai.blob.core.windows.net/fortifai/video_frame_poster.png">
        <source src="https://fortifai.blob.core.windows.net/fortifai/FortifAI-Demo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
          </div>
        </>
    );
    };

export default DemoVideos;