import React from "react";
import "./foundation.scss";
export default function DataInfo() {
  return (
    <section className="data-info">
      <h2>Transform Your Data Into An Insight-Generation Engine</h2>
      <div className="first-box">
        <p>
          Data is indispensable for any business, especially if that business
          wants to take advantage of a machine-learning-based platform to
          optimise its business operations. For example, in today’s e-commerce
          landscape, the customer experience is everything, and in order to
          convert browsers into buyers, you need to be able to accurately
          understand customers’ needs and buying behaviours to identify intent
          correctly. Having a wealth of data is what yields such insights, and
          this is key for e-commerce businesses wishing to compete and grow.
        </p>
        <p>
          Unfortunately, the data we often have to feed into a personalisation
          system is far from perfect and frequently of very low quality. The
          challenge with this kind of “bad data” is that even though there’s an
          abundance of it to analyse, attempting to do so can be like comparing
          apples to oranges. The age-old phrase of ‘garbage in, garbage out’ is
          quite apt regarding data.
        </p>
      </div>

      <div className="second-box">
        <h3>What makes data bad</h3>
        <ul>
          <li>
            Bad data is defined as missing, incomplete, or inconsistent data.
            Because data volume is critical for data-driven decision-making, a
            rule of thumb is that the more data you have, the better. However,
            more important than more data is having high-quality data.
          </li>
          <li>
            Data can be low-quality because it is merged from multiple sources
            with different data structures, fields, and naming conventions
            primarily driven by inefficient master data management or the lack
            of it.
          </li>
          <li>
            As the amount of data, data sources, and data types continue to
            increase, organisations require tools and strategies to help them
            transform data and derive business insights. Before organisations
            can achieve this, they must take the critical step of processing raw
            and messy data into clean and quality data
          </li>
        </ul>
      </div>
      <div className="second-box">
        <h3>Data Automation</h3>
        <ul>
          <li>
            This is where data automation steps in, revolutionising how
            organisations harness and leverage their data assets, enhancing
            decision-making.
          </li>
          <li>
            Data automation is a process that allows engineers to extract data
            from different sources, transform it into a usable and trusted
            resource, and load it into systems that end-users can access to
            solve business problems. The entire process is focused on ensuring
            that the data available for insight generation is clean, usable, and
            consistent.
          </li>
          <li>
            Data automation commonly involves Extract, Transform, and Load (ETL)
            processes. It can be used for structured and unstructured data from
            internal databases, external databases, cloud-based data sources,
            third-party applications, web services, and APIs.
          </li>
          <li>
            ETL enables engineers to extract data from different sources,
            transform the data into a usable and trusted resource, and load the
            data into the systems that end users can access and use downstream
            to solve business problems.
          </li>
          <li>
            Data automation can be applied to various data types, including
            structured and unstructured data. It can also be used across
            different data sources, such as internal databases, external
            databases, cloud-based data sources, and data from third-party
            applications, web services, and APIs.
          </li>
          <li>
            By automating repetitive and time-consuming tasks such as data
            ingestion, transformation, validation, cleansing, integration, and
            analysis, data automation helps organisations maximise their data
            and enables faster and easier data-driven decisions.
          </li>
        </ul>
      </div>
    </section>
  );
}
