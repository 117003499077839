import React from "react";
import "./Footer.scss";
import LogoImg from "../assets/logowhite.svg";
import fortifiai_logo_png_bw_black_back from "../assets/fortifiai_logo_png_bw_black_back.png";
import AIImg from "../assets/AI-white.svg";

import X from "../assets/x.svg";
import LinkedIn from "../assets/linkedin.svg";
function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <div className="logo-and-contacts">
          <div className="logo-img">
            <img src={fortifiai_logo_png_bw_black_back} alt="" />
            {/* <img src={LogoImg} alt="" />
            <span>
              FORTIF
              <img src={AIImg} alt="" />
            </span> */}
          </div>
          <div className="address">
            <div>
              <span className="city">Delhi</span>
              <span>
                WeWork Aldeco Center, Block A, Shivalik Colony, Delhi 110017
              </span>
            </div>
            <div>
              <span className="city">Mumbai</span>
              <span>
                WeWork Enam Sambhav, C 20, G Block, Bandra Kurla Center, Mumbai
                MH 400051
              </span>
            </div>

            <div className="contacts">
              <span>+91 98216 64290</span>
              <span>contact@fortifai.io</span>
              <div>
                <a
                  href="https://twitter.com/Fortif_AI"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={X} alt="" />
                </a>

                <a
                  href="https://www.linkedin.com/company/fortifai-inc/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkedIn} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="socials">
          <span>Resources</span>
          <div>
            <ul>
              <li>
                <a href="/insights">Blog</a>
              </li>
              <li>
                <a href="/reports">Fraud Articles</a>
              </li>
              <li>
                <a
                  href="https://fortifai.blob.core.windows.net/fortifai/2024-Report-to-the-Nations.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Occupational Fraud Report 2024
                </a>
              </li>
              {/* <li>Occupational Fraud Report 2023</li> */}
            </ul>
          </div>
        </div>
        <div className="company">
          <span>Company</span>
          <div>
            <ul>
              <a href="/solutions" style={{ textDecoration: "none" }}>
                <li>Solutions</li>
              </a>
              <a href="/security" style={{ textDecoration: "none" }}>
                <li>Security</li>
              </a>
              <a href="/about" style={{ textDecoration: "none" }}>
                <li>Our Founders</li>
              </a>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/cookies">Cookie Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="demo-booking">
          <span>Are you ready to start your</span>
          <span>fraud prevention journey?</span>
          <a href="/meeting" style={{ textDecoration: "none" }}>
            <button>Schedule a Demo</button>
          </a>
        </div>
      </div>
      <div className="copyright">
        <p>
          © 2024 FortifAI.inc. All Rights Reserved
          {/* <span onClick={handlePrivacyClick}> Privacy policy</span> •{" "}
          <span>Terms and Conditions</span> */}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
