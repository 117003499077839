import React from "react";
import "./Solutions.scss";
import SolutionFeatures from "./SolutionFeatures";
import Process from "./Process";
import KeyFeatures from "./KeyFeatures";
import Img from "../assets/image (1).png";
import { Helmet } from "react-helmet-async";
function SolutionHome() {
  const scrollToProcess = () => {
    const processSection = document.getElementById("process-section");
    if (processSection) {
      processSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>FortifAI</title>
        <meta
          name="description"
          content="End to end software platform for fraud prevention"
        />
        <meta property="og:title" content="FortifAI" />
        <meta
          property="og:description"
          content="End to end software platform for fraud prevention"
        />
      </Helmet>
      <section>
        <section className="solution-home">
          <div className="center-div">
            <div className="left-container">
              <h2>FortifAI</h2>
              <p>
                FortifAI provides a 360- degrees coverage for fraud risk
                management and prevention.
              </p>
              <button onClick={scrollToProcess}>See how it works</button>
            </div>
            <div className="right-container">
              <img src={Img} alt="" />
            </div>
          </div>
        </section>
        <SolutionFeatures />
        <Process />
        <KeyFeatures />
      </section>
    </>
  );
}

export default SolutionHome;
