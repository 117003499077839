import React from "react";
import "./foundation.scss";
export default function NextStep() {
  return (
    <section className="next-step">
      <h2>Take The Next Step</h2>
      <p>
        Start building your data foundation using FortifAI to enable the
        implementation of AI for a robust and proactive fraud risk management
        function. Want to know more about how FortifAI and our Data Foundation
        methodology can help you automate your fraud risk management?
      </p>
      <a href="/meeting">
        {" "}
        <button className="demo-button">Talk to an Expert</button>
      </a>
    </section>
  );
}
