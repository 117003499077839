import React from "react";
import Automation from "./automation.png";
import "./WorkFlowAutomation.scss";

function Workflow() {
  return (
    <>
      <section className="Workflow-blogs-text-header-container">
        <div className="center-div">
          <h2>Fraud Risk Management Workflow Automation Using SynthAI</h2>
          <p>
            <a
              href="/insights"
              style={{ textDecoration: "none", color: "#54565A" }}
            >
              Insights
            </a>{" "}
            &gt; Fraud Risk Management Workflow Automation Using SynthAI{" "}
          </p>
        </div>
      </section>
      <section className="workflowautomation-image-container">
        <div className="content">
          <h4>
            Automating the fraud risk management workflow to make it proactive
            and preventive, enabling business leaders to respond to potential
            fraud risk with alacrity and speed.
          </h4>
          <div className="content-paragraph">
            <i>
              {" "}
              Content courtesy –
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://a16z.com/author/zeya-yang/"
              >
                Zeya Yang
              </a>{" "}
              and Kristina Shen, Partner, Andreessen Horowitz
            </i>
          </div>
          <div className="content-paragraph">
            To date, generative AI applications have overwhelmingly focused on
            the divergence of information. That is, they create new content
            based on a set of instructions. According to Zeya and Kristina, this
            is{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://a16z.com/for-b2b-generative-ai-apps-is-less-more/"
            >
              wave 1
            </a>{" "}
            of the Generative AI use case and is more prevalent in B2C and not
            so much in B2B.
            <strong>
              In Wave 2, they believe, we will see more applications of AI to
              converge information.
            </strong>{" "}
            They will show us less content by synthesising the available
            information. Aptly, they refer to Wave 2 as{" "}
            <strong>synthesis AI </strong>(“SynthAI”) to contrast with Wave 1.
            While Wave 1 has created some value at the application layer, they
            believe Wave 2 will bring a step function change.
          </div>
          <div className="automation-image">
            <img src={Automation} alt="" />
          </div>
          <div className="content-paragraph">
            Ultimately, the battle among B2B solutions will focus less on
            dazzling AI capabilities and more on how these capabilities will
            help companies own (or redefine) valuable enterprise workflows.
          </div>
          <div className="content-paragraph">
            In essence, Wave 1 has been successful for more substantive writing
            in the brainstorming and drafting stages. Still, ultimately, the
            more creativity and domain expertise are required, the more human
            refinement is required. This is why the cost-benefit assessment
            breaks down in the B2B context. As knowledge workers, we are
            evaluating whether adding an AI-powered step to our workflows is
            worth our time or if we should do it ourselves.
            <strong>
              {" "}
              Today, with Wave 1 applications, the answer is frequently that
              we’re better off doing it ourselves.
            </strong>
          </div>
          <h4>
            <strong>
              {" "}
              Wave 2: Converging information for improved decision-making{" "}
            </strong>
          </h4>
          <div className="content-paragraph">
            As we move into the next wave of generative AI applications, we
            expect to see a shift in focus from information generation to
            information synthesis. In knowledge work, there is huge value in
            decision-making. Employees are paid to make decisions based on
            imperfect information, not necessarily the quantity of content
            generated to execute or explain these decisions. In many cases,
            longer is not better; it’s just longer.
          </div>
          <div className="content-paragraph">
            How can AI improve human decision-making?{" "}
            <strong>
              We believe LLMs will need to focus on synthesis and analysis —
              SynthAI — that improves the quality and/or speed of
              decision-making
            </strong>{" "}
            (remember our B2B diagram above), if not make the actual decision
            itself. The most obvious application here is to summarise high
            volumes of information that humans could never digest directly.
          </div>
          <div className="content-paragraph">
            The real value of SynthAI in the future will be in helping humans
            make better decisions faster.{" "}
            <strong>
              Instead of writing long-form responses based on a concise prompt,
              what if we could reverse engineer from massive amounts of data the
              concise prompt that summarises it?
            </strong>{" "}
            Zeya thinks there’s an opportunity to rethink the UX as one that
            conveys large amounts of information as efficiently as possible.
          </div>
          <div className="content-paragraph">
            {" "}
            As per Zeya, there is a visible shift toward{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://a16z.com/owning-the-workflow-in-b2b-ai-apps/"
            >
              Wave 2
            </a>{" "}
            , with more AI capabilities introduced to capture these end-to-end
            workflows.
          </div>
          <div className="content-paragraph">
            A workflow is a sequence of steps that someone performs to complete
            a task or do a part of their job. In knowledge work, the person
            needs to gather some information, apply context, and process it into
            a desired output – perhaps an insight or a decision. Application
            software strives to help us perform these workflows faster.
          </div>
          <div className="content-paragraph">
            One way it saves us time is by capturing, storing, and representing
            information in a way that’s easy to work with. The other is
            performing the work for us. The ultimate promise of software and
            automation is that it could complete the entire workflow for us. We
            don’t actually want to do the job ourselves; we’d prefer to just
            click a button to, and then we’re done! This is made possible by the
            use of SynthAI.
          </div>{" "}
          <h4>About FortifAi </h4>
          <div className="content-paragraph">
            FortifAI is an AI-powered and GenAI-enabled SaaS platform that
            automates the fraud risk management workflow. Its detection engine
            leverages SynthAI to synthesise disparate data sets and sources of
            data to prevent fraud risk. This enables businesses to proactively
            prevent fraud, saving them significant costs and avoiding regulatory
            risk and reputation damage. FortifAI has a large repository of
            detection algorithms, a powerful false-positive elimination engine,
            and a fully automated investigation management module. FortifAI has
            also enabled built-in background checks that allow real-time due
            diligence on 3rd parties
          </div>
          <div className="content-paragraph">
            If you want to know more about FortifAI or book a free trial for
            your organisation,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fortifai.io/meeting"
            >
              contact us now
            </a>{" "}
            or drop us an email at
            <a href="mailto:contact@fortifai.io"> contact@fortifai.io</a>
          </div>
          <span className="content-paragraph">
            <em>
              (Reposted with edits from the blog published on{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://a16z.com/"
              >
                a16z.com
              </a>{" "}
              in{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://a16z.com/for-b2b-generative-ai-apps-is-less-more/"
              >
                March 2023
              </a>{" "}
              and a follow-up blog in{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://a16z.com/owning-the-workflow-in-b2b-ai-apps/"
              >
                May 2024
              </a>
              )
            </em>
          </span>
        </div>
      </section>
    </>
  );
}

export default Workflow;
