import React from "react";
import "./Features.scss";
import Loading from "../assets/loading.svg";
import Protect from "../assets/protect.svg";
import Transfer from "../assets/transfer.svg";
import { useNavigate } from "react-router-dom";
import Fraud from "../assets/Fraud.svg";
import Focus from "../assets/fraudfocus.svg";
import GenAI from "../assets/GenAI.svg";
function SolutionsPage() {
  const navigate = useNavigate();

  const handleBookDemo = () => {
    navigate("/meeting");
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section className="solutions-page">
      <h2>AI powered Fraud risk management SAAS platform</h2>
      <div className="feature-content">
        <div className="feature-container">
          <div className="title">
            <img src={Loading} alt="" />
            <span>Prevent Fraud. Not just investigate</span>
          </div>
          <span class="text">
            Globally, more than 95% of frauds are discovered post-facto and only
            3% (acc.to 2022 ACFE Report) of frauds are detected through any kind
            of proactive mechanism. Adopting a fraud prevention technology
            platform can significantly save your company millions of dollars.
          </span>
        </div>
        <div className="feature-container" id="transfer">
          <div className="title">
            <img src={Transfer} alt="" />
            <span>
              Transform the Internal Audit Function into an Enterprise Value
              Driver
            </span>
          </div>
          <span class="text">
            Organisations lose up to 5% of revenue to occupational fraud with a
            median loss of USD 117000. Losses due to fraud not only impact your
            bottom line but also destroy shareholder value. High governance
            companies have higher enterprise value and better bottom lines.
            {/* A Harvard study has
            established that high-governance companies outperform low-
            low-governance companies in financial performance and stock market
            performance. */}
          </span>
        </div>
        <div className="feature-container">
          <div className="title">
            <img src={Protect} alt="" />
            <span>Monitor&gt; Detect &gt; Investigate &gt; Prevent</span>
          </div>
          <span class="text">
            Monitor organisation-wide transactions in real time to detect and
            investigate suspicious transactions. Prevent fraud before it
            happens.
          </span>
        </div>
        <div className="feature-container" id="fraud">
          <div className="title">
            <img src={Fraud} alt="" />
            <span>An end to end fraud prevention platform</span>
          </div>
          <span class="text">
            Designed by subject matter experts, FortifAI is an AI-powered,
            GenAI-enabled platform to monitor, detect, investigate and prevent
            internal corporate fraud.
          </span>
        </div>
        <div className="feature-container" id="focus">
          <div className="title">
            <img src={Focus} alt="" />
            <span>Focus on Fraud Prevention Not Data Analytics</span>
          </div>
          <span class="text">
            In the last decade, there has been an explosion of data within an
            organisation. As a result, internal audit teams spend majority of
            their time collecting data and analysing this data to identify fraud
            risk. This leaves them with little time to focus on fraud prevention
            and taking corrective actions.
          </span>
        </div>
        <div className="feature-container" id="genai">
          <div className="title">
            <img src={GenAI} alt="" />
            <span>Leverage AI and GenAI in fraud risk management</span>
          </div>
          <span class="text">
            There is gold mine of information that is available to you that can
            significantly improve your fraud risk management process. The use if
            AI and GenAI can unlock an infinite amount of value that will
            significantly improve your internal control function.
          </span>
        </div>
      </div>
      <button onClick={() => handleBookDemo()}>Schedule a Demo</button>
    </section>
  );
}

export default SolutionsPage;
